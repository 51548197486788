// const rootUrl = 'http://127.0.0.1:8360/admin/'
const rootUrl = 'https://www.brwanjia.com/admin/'

const Api = {
  // ucloudUS3配置
  rootUrl: rootUrl,
  bucketUrl: 'https://wanjia.bra-saopaulo.ufileos.com/',
  testUrl: 'https://develoment.cn-gd.ufileos.com/',
  bucketName: 'wanjia',
  testName: 'develoment',
  tokenPublicKey: 'TOKEN_26efdf59-9641-46e9-9874-4397a16036e0',
  tokenPrivateKey: '11b4c341-da38-4a6e-ae88-18c8cb836caa',
  testPK: 'TOKEN_7391a6e2-234f-4e4c-9f91-8b494474bebc',
  testRK: 'e781c33a-c564-4e45-8f66-5c9d5af29905'
}

// import api from './config/api'
// Axios.defaults.baseURL = api.rootUrl;

export default Api
