import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
export default new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      redirect: '/dashboard/welcome',
      component: () => import('@/components/DashboardPage'),
      children: [
        {
          path: 'welcome',
          name: 'welcome',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/WelcomePage')
        },
        {
          path: 'goods',
          name: 'goods',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Goods/GoodsPage')
        },
        {
          path: 'goods/add',
          name: 'goods_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Goods/GoodsAddPage')
        },
        {
          path: 'nature',
          name: 'nature',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Nature/NaturePage')
        },
        {
          path: 'specification/detail',
          name: 'specification_detail',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Specification/SpecificationAddPage')
        },
        {
          path: 'category',
          name: 'category',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Category/CategoryPage')
        },
        {
          path: 'category/add',
          name: 'category_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Category/CategoryAddPage')
        },
        {
          path: 'order',
          name: 'order',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Order/OrderPage')
        },
        {
          path: 'order/detail',
          name: 'order_detail',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Order/OrderDetailPage')
        },
        {
          path: 'user',
          name: 'user',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/User/UserPage')
        },
        {
          path: 'user/add',
          name: 'user_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/User/UserAddPage')
        },
        {
          path: 'shipper',
          name: 'shipper',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Shipper/ShipperPage')
        },
        {
          path: 'shipper/list',
          name: 'shipper_list',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Shipper/ShipperListPage')
        },
        {
          path: 'shipper/add',
          name: 'shipper_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Shipper/ShipperAddPage')
        },
        {
          path: 'freight',
          name: 'freight',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Freight/FreightPage')
        },
        {
          path: 'except_area',
          name: 'except_area',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Freight/ExceptAreaPage')
        },
        {
          path: 'except_area/add',
          name: 'except_area_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Freight/ExceptAreaAddPage')
        },
        {
          path: 'freight/add',
          name: 'freight_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Freight/FreightAddPage')
        },
        {
          path: 'notice',
          name: 'notice',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Settings/NoticePage')
        },
        {
          path: 'ad',
          name: 'ad',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Ad/AdPage')
        },
        {
          path: 'ad/add',
          name: 'ad_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Ad/AdAddPage')
        },
        {
          path: 'comment',
          name: 'comment',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Comment/ComPage')
        },
        {
          path: 'comment/add',
          name: 'comment_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Comment/ComAddPage')
        },
        {
          path: 'shopcart',
          name: 'shopcart',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/ShopCart/ShopCartPage')
        },
        {
          path: 'keywords',
          name: 'keywords',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Keywords/KeywordsPage')
        },
        {
          path: 'keywords/add',
          name: 'keywords_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Keywords/KeywordsAddPage')
        },
        {
          path: 'goodsgalleryedit',
          name: 'goodsgalleryedit',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/GoodsGallery/GoodsGalleryEditPage')
        },
        {
          path: 'admin',
          name: 'admin',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Admin/AdminPage')
        },
        {
          path: 'admin/add',
          name: 'admin_add',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Admin/AdminAddPage')
        },
        {
          path: 'settings/showset',
          name: 'showset',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Showset/ShowSetPage')
        }
      ]
    },
    {
      path: '/wap',
      name: 'wap',
      meta: {
        roles: ['admin', 'user']
      },
      component: () => import('@/components/WapPage'),
      children: [
        {
          path: 'order',
          name: 'order',
          meta: {
            roles: ['admin', 'user']
          },
          component: () => import('@/components/Wap/OrderPage')
        },
        {
          path: 'goods',
          name: 'goods',
          meta: {
            roles: ['admin']
          },
          component: () => import('@/components/Wap/GoodsPage')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        roles: ['admin', 'user']
      },
      component: () => import('@/components/LoginPage')
    },
    {
      path: '*',
      redirect: '/login'
    }
  ]
})
