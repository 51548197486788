import Vue from 'vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App'
import router from './router'
import store from './store'
import api from './config/api'
import './element.js'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueAxios, Axios)

const whiteCode = [0, 100] // 白名单code

router.beforeEach((to, from, next) => {
  let { userAgent } = navigator
  let mobileAgents = ['Android', 'iPhone', 'Symbian', 'WindowsPhone', 'iPad', 'BlackBerry', 'Windows CE']
  if (to.name === 'login') {
    next()
    return
  }
  let token = localStorage.getItem('token') || ''
  let role = JSON.parse(localStorage.getItem('userInfo') || '').id === 26 ? 'admin' : 'user'
  //配置接口信息
  Axios.defaults.baseURL = api.rootUrl
  Axios.defaults.headers.common['x-xzzshop-token'] = token
  if (!token && to.name !== 'login') {
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else if (token && to.meta.roles.includes(role)) {
    let toAgent = mobileAgents.some(agent => {
      if (userAgent.includes(agent)) return true
      else return false
    })
    if (to.path.includes('wap')) {
      next()
      return
    }
    if (toAgent) {
      next({
        path: '/wap'
      })
    } else next()
  } else {
    next({ path: from.path })
  }

  Axios.interceptors.response.use(
    res => {
      if (whiteCode.includes(res.data.errno)) {
        return Promise.resolve(res)
      } else {
        return Promise.reject()
      }
    },
    () => {
      app.$message({
        type: 'error',
        message: '操作失败!'
      })
    }
  )
})

Vue.config.productionTip = false
document.title = '巴西万家超市后台管理'

const app = new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
